<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="700"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">

                <v-card
                    flat
                >
                    <v-card-text
                        v-if="loading"
                        style="min-height: 409px;"
                    >
                        <v-col
                            class="text-subtitle-1 text-center"
                            cols="12"
                        >
                            {{ $t("Загрузка...") }}
                        </v-col>

                        <v-col cols="12">
                            <v-progress-linear
                                color="#a94442"
                                indeterminate
                                rounded
                                height="6"
                            ></v-progress-linear>
                        </v-col>

                    </v-card-text>
                    <v-card-text v-else>

                        <div class="form-box-title title-with-right-part">
                            <span>
                                {{ $t("Документ") }}
                            </span>
                            <div class="twrp-content">
                                <v-text-field
                                    v-model="protocolSearch"
                                    :placeholder="$t('Поиск')"
                                    hide-details
                                    dense
                                    clearable
                                    @click:clear="protocolSearch = null"
                                    class="twrp-search"
                                    append-icon="fas fa-search"
                                >
                                </v-text-field>
                            </div>
                        </div>

                        
                        <v-list
                            v-if="protocols.length > 0"
                            class="modal-select-list"
                            dense
                            outlined
                        >
                            <v-list-item-group
                                v-model="protocol"
                                color="cyan darken-1"
                            >
                                    <v-list-item
                                        v-for="item in protocols"
                                        :key="item.id"
                                        :value="item"
                                    >
                                        <!-- Иконка документа--> 
                                        <v-list-item-avatar>
                                            <v-icon>
                                                fas fa-file-alt
                                            </v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content v-tooltip.top-center="item.theme">

                                            <v-list-item-title>
                                                {{ $t(item.doctype) }} № {{ item.regnumber }} {{ $t("от_время") }} {{ $moment(item.regdate).format("DD.MM.YYYY") }}{{ item.id == currentDocumentId ? ` (${$t('Текущий_документ')})` : '' }}
                                            </v-list-item-title>

                                            <v-list-item-subtitle>
                                                {{ item.theme }}
                                            </v-list-item-subtitle>

                                        </v-list-item-content>

                                        <v-list-item-icon>
                                            <div @click.stop="onOpenDocumentInNewTab(item)">
                                                <v-icon
                                                    small
                                                    v-tooltip.left-center="$t('Открыть_документ_в_новой_вкладке')"                                                    
                                                >
                                                    fas fa-external-link-square-alt
                                                </v-icon>
                                            </div>
                                        </v-list-item-icon>

                                    </v-list-item>

                            </v-list-item-group>
                            <v-divider></v-divider>
                            <v-pagination
                                v-model="page"
                                :length="Math.ceil(total/perPage)"
                                :total-visible="9"
                                class="cust-pagination"
                                color="teal"
                            ></v-pagination>
                        </v-list>
                        <v-row v-else no-gutters>
                            <v-col cols="12" sm="12" md="12">
                                <div class="modal-list-placeholder">
                                    {{ $t("К_сожалению,_по_Вашему_запросу_ничего_не_найдено") }}
                                </div>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="protocol"
                >
                    {{ $t("Связать") }}
                </v-btn>

                <v-btn 
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
    name: "AddProtocolLinkDlg",
    data() {
        return {
            title: "Привязать_протокол",
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            openSearch: false
        }
    },
    computed: {
        ...mapGetters('dialogs/addProtocolLink', { visible: 'isVisible', loading: 'isLoading', protocols: 'getProtocols', perPage: 'getDocumentPerPage', total: 'getDocumentsTotal' }),
        ...mapGetters('actionsource', { currentDocumentId: 'getDataSourceDocumentId' }),
        protocol: {
            get: function() {
                return this.$store.getters['dialogs/addProtocolLink/getSelectedProtocol'];
            },
            set: function(v) {
                this.$store.commit('dialogs/addProtocolLink/SET_SELECTED_PROTOCOL', v);
            }
        },
        protocolSearch: {
            get: function() {
                return this.$store.getters['dialogs/addProtocolLink/getProtocolSearch'];
            },
            set: _.debounce(function(v) {
                this.$store.dispatch('dialogs/addProtocolLink/changeSearch', v);
            }, 500)
        },
        page: {
            get: function() {
                return this.$store.getters['dialogs/addProtocolLink/getDocumentPage'];
            },
            set: function(v) {
                this.$store.dispatch('dialogs/addProtocolLink/changePage', v);
            }
        }
    },
    methods: {
        ...mapActions('dialogs/addProtocolLink', [ 'ok', 'cancel', 'fetchProtocols' ]),
        onOpenDocumentInNewTab(document) {

            let routeData = this.$router.resolve({
                name: document.doctypeof,
                query: { id: document.id }
            });

            // временно удаляем идентификатор сессии, для того, что-бы новая вкладка не скопировала его в свое хранилище
            var sessionId = sessionStorage.getItem('SessionId');
            sessionStorage.removeItem('SessionId');

            window.open(routeData.href, '_blank');
            
            // устанавливаем идентификатор сессии
            sessionStorage.setItem('SessionId', sessionId);
        }
    }
}
</script>